#headline {
    color: aliceblue;
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-size: 2.5em;
}

#subHeadline {
    color: aliceblue;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1.5em;
    font-weight: bold;
}

#text {
    color: aliceblue;
    font-size: 1.5vh;
    padding: 2px;
    white-space: break-spaces;
    line-height: 3vh;
}

#container {
    height: 100%;
    margin: auto;
    overflow: auto;
}

a {
    color: #93deff;
}

a:hover {
    color: #5fa1ff;
}