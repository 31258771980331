#sidebarContainer {
    position: absolute;
    width: 16vw;
    height: calc(100vh - 60px - 80px);
    background: rgb(0, 0, 0, 0.75);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.optionText {
    font-size: 1.6em;
    text-align: center;
    margin-top: 5vh;
    padding: 4px;
    color: antiquewhite;
}

.optionText:hover {
    cursor: pointer;
}

.bigText {
    text-align: center;
    margin-top: 4vh;
    padding: 4px;
    color: bisque;
    font-size: 1.8em;
    font-weight: bold;
}

.bigText:hover {
    cursor: pointer;
}